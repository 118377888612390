@font-face {
  font-family: "myFont";
  src: local("MyFont"), url("./assets/fonts/Tajawal-Medium.ttf");
  line-height: 110%;
}

@media only screen and (max-width: 600px) {
  .page-content {
    width: 100% !important;
    padding: 0 10px;
    margin: 0 auto !important;
    margin-top: 10px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: myFont !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: myFont !important;
}

.search-icon {
  font-size: 50px;
}

html,
body,
#root {
  height: 100% !important;
}

.container-fluid,
.container {
  /* height: 45vh !important; */
}

header {
  position: relative;
  height: 25vh;
  background-image: url("assets/images/header.jpg");
  background-size: cover;
  margin-bottom: 20px;
}

.logo-container {
  flex: 1;
  flex-direction: row;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
}

.list-container {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.client-logo-container {
  flex: 1;
}

.client-logo-container img {
  width: 145px;
}

.header-navbar {
  /* flex: 0 auto; */
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.header-text-container {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.header-text-container h1 {
  font-weight: bold;
  color: #fff;
}

.header-text-container p {
  color: #fff;
  font-size: 40px;
}

.header-navbar a {
  margin: 0 20px;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}

.header-navbar a:hover {
  color: #f7b91a;
}

.header-navbar a.active {
  color: #f7b91a;
}

.header-navbar img {
  width: 180px;
  text-align: right;
}

.inline-block {
  display: inline-block;
  flex: 1;
}

h2 {
  padding: 100px 0;
  font: 44px "Arial";
  text-align: center;
}

header h2 {
  color: white;
}

svg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4vw;
  /* set height to pixels if you want angle to change with screen width */
}

#client-logo {
  width: 250px;
  height: 250px;
  border-radius: 125px;
}

section#form-container {
  text-align: center;
}

.page-content {
  margin: 0 auto;
  width: 40%;
}

label {
  width: 100%;
  text-align: right;
}

#custom-primary-btn {
  background-color: #61338c;
  color: #fff;
}

#total {
  font-size: 20px;
  color: #61338c;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container > span {
  flex: 0 auto !important;
}

div.error {
  text-align: right;
  color: #a10000;
  font-size: 12px;
}

.request-status {
  background-color: red;
  padding: 10px;
}

.order-details-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 20px;
}

.order-details-container div {
  display: flex;
  padding: 10px 0;
  font-size: 18px;
}

.order-details-container + div,
span:first-child {
  flex: 1;
  text-align: right;
  color: #61338c;
}

.order-details-container + div,
span:last-child {
  flex: 1;
  text-align: left;
  color: #000;
}

.status-container {
  color: #fff !important;
  padding: 5px 10px;
}

.status-container.pending {
  background-color: #ddb565;
}

.status-container.approved {
  background-color: #81b900;
}

.status-container.canceled {
  background-color: #a10000;
}

.custom-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-modal-content {
  background-color: #fff;
  border-radius: 10px;
  min-width: 35%;
  text-align: right;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.custom-modal-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.19);
  padding: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

.custom-modal-body,
.timedout-order-container {
  border-bottom: 0 !important;
}

.custom-modal-body h3 {
  font-size: 18px !important;
}

.custom-modal-body p {
  font-size: 14px;
}

.close-modal-btn {
  border: none;
  background-color: transparent;
  width: 30px;
  margin: 0 10px;
}

.timedout-order-container {
  margin: 0 10px;
  align-self: stretch;
  padding: 0 !important;
}

.timedout-order-container * {
  border-bottom: 0 !important;
}

.timedout-order-container h3 {
  color: #61338c;
}

.custom-modal-footer {
  width: 100%;
  padding-top: 10px;
}

.no-outline {
  outline: none !important;
}
